  import 'slick-slider';

  $(document).ready(function () {

    $('.intro__slider').slick({
      fade: true,
      arrows: false,
      waitForAnimate: false,
      infinite: false,
      dots: true,
      adaptiveHeight: true,
      appendDots: $('.appendDots'),
      initialSlide: 3,
      asNavFor: '.intro__slider-previews',
    });

    $('.slider-preview').each(function (i) {
      $(this).mouseenter(function () {
        $('.intro__slider').slick('slickGoTo', i);
      });

    });

    //-

    $('.posters__row').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      infinite: false,
      responsive: [{
          breakpoint: 1280,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });

    $('.module-icons').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      infinite: false,
      responsive: [{
        centerMode: true,
        breakpoint: 767,
        settings: {
          initialSlide: 1,
          slidesToShow: 1
        }
      }]
    });
    //
    $('.block-slider').slick({
      adaptiveHeight: true,
      infinite: false,
    });
    if ($('.block-slider').length) {

      function arrowsPos() {
        if ($(window).width() <= 767) {
          let img_height = $('.block-slider img').outerHeight();
          $('.block-slider .slick-arrow').css('top', img_height / 2);
        } else {
          $('.block-slider .slick-arrow').css('top', '50%');
        }

      }
      arrowsPos();
      $(window).on('resize', function () {
        arrowsPos()
      });
    }
    //-

  });