let decor = '<div class="container"> <div class="intro__decor"> <div></div><div></div></div></div>';
let image = $('.intro__image');
let tooltip = '<div class="location-tooltip"> <p class="location-tooltip__title">ЛЕДОВЫЙ ДВОРЕЦ ICE PALACE</p><p class="location-tooltip__desc">Вместимость основной арены - 3 500 мест, тренировочной арены - 950 мест.</p></div>';


let objects = [{
        title: "Комплекс водных видов спорта",
        desc: "В процессе стрительства",
        top: '9%',
        left: '36%',
    },
    {
        title: "Ледовый дворец Ice Palace",
        desc: "Основная арена - 3500 мест, тренировочная арена - 850 мест",
        top: '3%',
        left: '0%',
    }, {
        title: 'Комплекс учебно-тренировочных залов "Чемпион"',
        desc: "11 залов по 9 олимпийским видам спорта",
        top: '2%',
        left: '62%',
    }, {
        title: 'Многофункциональный спортивный комплекс "Баскет-холл"',
        desc: 'Основная арена - 7500 мест, тренировочная - 1500 мест ',
        top: '10%',
        left: '78%',
    }
]

// methods
function showPosters(index) {
    $('.object-point').removeClass('active');
    $(`.object-point[data-id="${index}"]`).addClass('active');
    $('.intro-posters').find('.intro-posters__group').removeClass('active');
    $('.intro-posters').find('.intro-posters__group').eq(index).addClass('active');
}

function showTooltip(index) {
    $('.location-tooltip').css({
        top: objects[index].top,
        left: objects[index].left
    });
    $('.location-tooltip__title').text(objects[index].title);
    $('.location-tooltip__desc').text(objects[index].desc);
}

// events
document.addEventListener('DOMContentLoaded', function () {
    image.append(decor);
    $('.intro__image .container').append(tooltip);
    showPosters(0);
    showTooltip(0);
});
$('.object-point').on('mouseover', function () {
    let id = $(this).data('id');
    showPosters(id);
    showTooltip(id);
});