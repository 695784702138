if ($('[data-address]')) {
    $('[data-address]').each(function (index, el) {
        var adr = $(this).attr('data-address');

        function init() {
            var myGeocoder = ymaps.geocode(adr);
            myGeocoder.then(
                function (res) {
                    var coord = res.geoObjects.get(0).geometry.getCoordinates();

                    var myMap = new ymaps.Map(el, {
                        center: coord,
                        zoom: 16
                    });

                    myMap.geoObjects.add(new ymaps.Placemark(coord, {
                        balloonContentHeader: adr
                    }))
                },
                function (err) {
                    console.log('Error');
                }
            );
        }
        ymaps.ready(init);
    });
}