import './components/main';


import './components/intro';

import './components/slicer';
import './components/sticky';
import './components/fancybox';
import './components/sliders';
import './components/maps';

import './components/visuallimpaired';