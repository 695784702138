import '../lib/resizeSensor';
import StickySidebar from 'sticky-sidebar/dist/sticky-sidebar';

if ($('.js-sticky').length) {
  let sidebar = new StickySidebar('.js-sticky', {
    topSpacing: 0,
    responsiveWidth: true,
    resizeSensor: true,
    minWidth: 1024
  });

  var stickyInterval;
  stickyInterval = setInterval(function () {
    if (sidebar) {
      sidebar.updateSticky();
    }
  }, 500);

  // navigation podmenu

  function sNav() {
    let nav = $('.sidebar-navigation');
    nav.find('ul li ul').slideUp();
    nav.find('ul li.active ul').slideDown();

    nav.find('ul li').each(function () {

      if ($(this).find('ul').length) {
        $(this).addClass('has-podmenu');


        $(this).children('a').click(function (e) {

          if (!$(this).parent().hasClass('active')) {
            e.preventDefault();
            // удалить у всех active
            nav.find('ul li ul').slideUp();
            $('.sidebar-navigation >ul>li').removeClass('active');
            // добавить active
            $(this).parent().find('ul').slideDown();
            $(this).parent().addClass('active');
            sidebar.updateSticky();

          } else {
            e.preventDefault();
            $(this).parent().find('ul').slideUp();
            $(this).parent('li').toggleClass('active');
            sidebar.updateSticky();

          }
        });

      }

    });
  }
  sNav();



  // sidebar-navigation mobile

  let s_nav = $('.sidebar-navigation');
  let toggle_btn_html = "<div class='sidebar-nav-toggle'><div class='container'>Разделы</div></div>";



  function addToggleBtn() {
    if ($(window).outerWidth() < 1024 && $('.sidebar-nav-toggle').length === 0) {

      $('.page').prepend(toggle_btn_html);
      s_nav.hide();

    } else if ($(window).outerWidth() >= 1024) {
      $('.sidebar-nav-toggle').remove();
      s_nav.show();
    }
  }

  $(document).ready(function () {
    if ($('.page__sidebar').find('.sidebar-navigation').length) {
      addToggleBtn();
    }
  });


  $('body').delegate('.sidebar-nav-toggle', 'click', function () {
    s_nav.slideToggle();
    $(this).toggleClass('active');
  });
  //-


  $(window).on('resize', function () {
    if ($('.page__sidebar').find('.sidebar-navigation').length) {
      addToggleBtn();
    }
  });
}